import './mixPanelInit';

import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { abTestingFactory, ABTestingService } from '@providers/ab-testing/ab-testing.service';
import { boostrapProviders } from '@providers/bootstrap';
import { remoteConfigFactory, RemoteConfigService } from '@providers/config/remote-config.service';
import { CountryInitializer, countryInitializerFactory } from '@providers/country/country.initializer';
import { DeviceDetectorService } from '@providers/device-ks/device-detector.service';
import {
  DevicePlatformInitializer,
  devicePlatformInitializerFactory,
} from '@providers/device-ks/device-platform.initializer';
import { FreemiumControlService } from '@providers/freemium/freemium-control.service';
import { FriendsService } from '@providers/friends/friends';
import { GlobalErrorHandler } from '@providers/global/global-error-handler';
import { GtvZone } from '@providers/gtv/gtv';
import { TranslateInitializer, translateInitializerFactory } from '@providers/i18n/translate.initializer';
import { LoadingService } from '@providers/loading/loading.service';
import { LocalStorageModule } from '@providers/local-storage/local-storage.module';
import { KochavaService } from '@providers/metrics/kochava/kochava.service';
import { ModalService } from '@providers/modal/modal';
import { PagesService } from '@providers/pages/pages.service';
import { PreviousRouteService } from '@providers/previus-route/previus-route.service';
import { RegisterWebService } from '@providers/registerWeb/registerWeb';
import { MovistarHomeModule } from 'src/app/pages/movistar-home/movistar-home.module';
import { TokenInterceptor } from 'src/providers/api/token.interceptor';
import { KSFirebase } from 'src/providers/firebase/ksfirebase';
import { authTokenFactory, AuthTokenService } from 'src/providers/user/auth-token.service';
import { CountryResolver } from 'src/resolvers/country.resolver';
import { TrackJS } from 'trackjs';

import { SmartRouterAnimationWrapModule } from '../components/smart-router-animation-wrap/smart-router-animation-wrap.module';
// environments vars
import { environment } from '../environments/environment';
import { ErrorInterceptor } from '../providers/api/error-interceptor';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';
import { SharedModule } from './shared/shared.module';

TrackJS.install({
  token: 'a693ea8020e64a579b2ae76104c6c406',
  application: 'kanto',
  version: environment.version,
  // for more configuration options, see https://docs.trackjs.com
});

export const otherServices = [
  PagesService,
  FriendsService,
  GtvZone,
  ModalService,
  // KSFirebase,
  FreemiumControlService,
  RegisterWebService,
  KochavaService,
];

export const httpLoaderFactory = (http: HttpClient) =>
  new TranslateHttpLoader(http, './assets/i18n/', `.json?v=${environment.version}`);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'karaokeApp' }),
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AppRoutingModule,
    SharedModule,
    SmartRouterAnimationWrapModule,
    MovistarHomeModule,
    LocalStorageModule.detect(),
  ],
  providers: [
    CountryInitializer,
    CountryResolver,
    TranslateInitializer,
    ABTestingService,
    PreviousRouteService,
    RemoteConfigService,
    KSFirebase,
    LoadingService,
    DeviceDetectorService,
    DevicePlatformInitializer,
    {
      provide: APP_INITIALIZER,
      useFactory: devicePlatformInitializerFactory,
      deps: [DevicePlatformInitializer],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: countryInitializerFactory,
      deps: [CountryInitializer],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: translateInitializerFactory,
      deps: [TranslateInitializer],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: abTestingFactory,
      deps: [ABTestingService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: authTokenFactory,
      deps: [AuthTokenService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: remoteConfigFactory,
      deps: [RemoteConfigService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    boostrapProviders,
    otherServices,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {}
}
