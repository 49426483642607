import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { throwError } from 'rxjs';
import { map } from 'rxjs/operators';

import { ABTestingService } from '../ab-testing/ab-testing.service';
import { Api } from '../api/api';
import { CountryService } from '../country/country';
import { LocalStorage } from '../local-storage/local-storage';

@Injectable()
export class Config {
  static countriesConfig: { [country: string]: { lang: string } } = {
    BR: {
      lang: 'pt',
    },
    MX: {
      lang: 'es',
    },
    PE: {
      lang: 'es',
    },
    CO: {
      lang: 'es',
    },
    AR: {
      lang: 'es-ar',
    },
    EC: {
      lang: 'es',
    },
    CL: {
      lang: 'es',
    },
    ES: {
      lang: 'es',
    },
    US: {
      lang: 'en',
    },
  };

  plans = 'plan/list';
  songsPremium = this.api.get('song/premium');
  configData: any = { timeData: {} };
  timeReq: any;
  timeData: any = {};
  confData: any = null;

  plansData: any = null;
  SongPremiumData: any = null;

  countrysArray = [
    'PE',
    'CL',
    'AR',
    'PY',
    'UY',
    'BO',
    'EC',
    'CO',
    'BR',
    'VE',
    'PA',
    'MX',
    'HN',
    'GT',
    'NI',
    'DO',
    'SV',
    'CR',
  ];
  pay1erWithPayU = ['CO', 'MX'];
  pay1erWithConekta = ['MX'];
  pay1erWithEBANX = ['BR'];
  // Peru, Colombia, chile, ecuador, guatemala, honduras, bolivia, el salvador,
  // Y México

  countriesPayWithSafety = [];
  // countriesPayWithSafety = ['PE', 'CL', 'EC', 'CO', 'MX', 'BR'];

  countriesFreemium: string[] = [];

  countriesNoFreemium: string[] = [
    'ES',
    'BR',
    'PE',
    'CL',
    'AR',
    'PY',
    'UY',
    'BO',
    'EC',
    'CO',
    'BR',
    'VE',
    'PA',
    'MX',
    'HN',
    'GT',
    'NI',
    'DO',
    'SV',
    'CR',
    'US',
    'NN',
  ];

  countriesSMSWizard: string[] = [];

  defaultRegisterMethod = ['login', 'tv'];

  allowedCountries = ['BR', 'MX', 'PE', 'CO', 'AR', 'EC', 'CL', 'ES', 'US', 'NN'];

  constructor(
    public api: Api,
    public country: CountryService,
    private abTesting: ABTestingService,
    private localStorage: LocalStorage,
    private translate: TranslateService
  ) {
    const songsPlayedPromise = this.localStorage.getItem('songsPlayed');
    const sungSongsPromise = this.localStorage.getItem('sungSongs');

    Promise.all([songsPlayedPromise, sungSongsPromise]).then(([songsPlayed, sungSongs]) => {
      if (songsPlayed === undefined || songsPlayed === null) {
        this.localStorage.setItem('songsPlayed', (0).toString());
      }
      if (sungSongs === undefined || sungSongs === null) {
        this.localStorage.setItem('sungSongs', (0).toString());
      }
    });
  }

  countryIsNotFreemium(country: string) {
    return this.countriesNoFreemium.indexOf(country) !== -1;
  }

  getSettings(reload?: boolean): Promise<any> {
    if (this.confData && !reload) {
      return Promise.resolve(this.confData);
    }
    return this.api
      .get('config/init')
      .toPromise()
      .then(res => {
        this.confData = res.success ? res.data : null;
        return this.confData;
      })
      .catch(e => null);
  }

  getPlans(country?: string) {
    return this.api
      .get(`plan/list`, {
        country,
        language: this.translate.currentLang,
      })
      .pipe(
        map(res => {
          if (res.success) {
            return res.data;
          } else {
            throw throwError(res.message || 'Ocurrio un error inesperado');
          }
        })
      )
      .toPromise();
  }

  isAllowedCountry() {
    return this.allowedCountries.indexOf(this.country.currentCountry) !== -1;
  }

  isAFremiumCountry() {
    return this.countriesFreemium.indexOf(this.country.currentCountry) !== -1;
  }

  isAvailableFreemium() {
    return this.isAFremiumCountry();
  }

  isAvailablleSMSWizardCountry() {
    return this.countriesSMSWizard.indexOf(this.country.currentCountry) !== -1;
  }

  isAvailablleSMSWizard() {
    return this.isAvailablleSMSWizardCountry();
  }

  isAvailableNewFreemium() {
    return this.abTesting.iAmCase('newFreemium', 'b');
  }
}
