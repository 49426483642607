import { Injectable } from '@angular/core';

import { Platform } from '../../platform/platform';
declare let window;

@Injectable()
export class KochavaService {
  ready = false;
  constructor(private platform: Platform) {
    if (this.platform.isBrowser()) {
      this.loadKochava();
    }
  }

  loadKochava() {
    if (window.kochava) {
      console.log('Kochava snippet already included');
      return;
    }

    const scriptURL = 'https://storage.googleapis.com/kochava-web-assets/kochava.js/v3/kochava.min.js';
    const kochavaScript = document.createElement('script');
    kochavaScript.type = 'text/javascript';

    // To disable caching, change this value to true
    const disableCaching = false;

    kochavaScript.src = disableCaching ? scriptURL + '?c=' + Math.random() : scriptURL;
    kochavaScript.async = true;

    kochavaScript.onload = () => {
      // ADD THE START CALL HERE
      window.kochava.startWithAppGuid('kokanto-tv-d4ftcvz'); //kanto-tv GUID
      this.ready = true;
    };

    kochavaScript.onerror = () => {
      console.log('kochava error');
    };
    document.head.appendChild(kochavaScript);
  }

  sendEvent(eventName: string, eventData?: string | Record<string, any>) {
    if (this.ready) {
      return window.kochava.sendEvent(eventName, eventData);
    }
    console.log('kochava is not ready');
  }

  identify(key: string, data?: any) {
    if (this.ready) {
      return window.kochava.registerIdentityLink(key, data);
    }
    console.log('kochava is not ready');
  }
}
