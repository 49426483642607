import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/database';
import { Router } from '@angular/router';
import { AdsService } from '@providers/ads/ads';
import { DeviceKS } from '@providers/device-ks/device-ks';
import {
  collection,
  deleteDoc,
  doc,
  DocumentReference,
  setDoc,
  updateDoc,
} from '@providers/firebase/firestore.functions';
import { documentFromOnSnapshot, valueChanges } from '@providers/helpers/firestore';
import { Notifications } from '@providers/notifications/notifications';
import { SongCounterService } from '@providers/song-counter/song-counter';
import { SessionService } from '@providers/user/session.service';
import { UserLastActionService } from '@providers/user/user-last-action.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, take, tap } from 'rxjs/operators';

import { IMedia, RemoteControlsInterface, UserSessionResponseInterface } from '../../interfaces/interfaces';
import { SmartListService } from '../smartlist/smart-list.service';
import { User } from '../user/user';
import { getSourceAndTone } from './player.helpers';
import { PlayerService } from './player.service';
import { RemoteActionsService } from './remote-actions.service';
import { RemoteScoreStatusService } from './remote-score-status.service';

@Injectable()
export class RemotePlayerService {
  subCurrentPlayingRemote: Subscription;
  subControlsPlayerRemote: Subscription;
  currentPlayingDoc: DocumentReference<IMedia>;
  currentPlaying$: Observable<IMedia>;
  currentPlaying: IMedia;

  controlsDoc: DocumentReference<RemoteControlsInterface>;
  controlsObject: AngularFireObject<RemoteControlsInterface>;
  controls$: Observable<RemoteControlsInterface>;
  controls: RemoteControlsInterface = {
    play: false,
    pause: false,
    isPlaying: true,
    volume: undefined,
  };
  remoteSongCheck: Subject<IMedia> = new Subject();

  constructor(
    private db: AngularFireDatabase,
    private smartList: SmartListService,
    private player: PlayerService,
    public user: User,
    private router: Router,
    private location: Location,
    private notifications: Notifications,
    private session: SessionService,
    private adsService: AdsService,
    private userLastAction: UserLastActionService,
    private remoteActions: RemoteActionsService,
    private remoteScoreStatus: RemoteScoreStatusService,
    private devideKs: DeviceKS,
    private songCounter: SongCounterService
  ) {
    this.session.session$
      .pipe(
        filter(sessionData => sessionData !== undefined && sessionData !== null),
        distinctUntilChanged((previous, current) => previous?.token === current?.token)
      )
      .subscribe(() => {
        this.suscribeToCurrentPlaying();
        this.suscribeToRemoteControls();

        this.remoteActions.subscribeToRemoteActions();
        this.remoteScoreStatus.subscribeToScoreStatus();

        this.player.onProgress.subscribe(currentPlaying => {
          currentPlaying.currentPercent = (currentPlaying.currentTime / currentPlaying.duration) * 100;
          if (currentPlaying.lastTimeSended === 0 || currentPlaying.currentTime - currentPlaying.lastTimeSended > 1) {
            currentPlaying.lastTimeSended = currentPlaying.currentTime;
            this.controls.isPlaying = true;
            updateDoc(this.controlsDoc, {
              isPlaying: true,
              currentTime: currentPlaying.currentPercent,
            });
            this.controlsObject
              .update({
                isPlaying: true,
                currentTime: currentPlaying.currentPercent,
              })
              .then(() => {});
          }
        });

        this.player.onNextSong.subscribe(this.next.bind(this));
        this.player.onPreviusSong.subscribe(this.previus.bind(this));
      });
  }
  play() {
    this.controls.isPlaying = true;
    return updateDoc(this.controlsDoc, {
      play: true,
      isPlaying: true,
    });
    // return true;
  }

  pause() {
    this.controls.isPlaying = false;
    return updateDoc(this.controlsDoc, {
      pause: true,
      isPlaying: false,
    });
    // return false;
  }

  noPlaying() {
    updateDoc(this.controlsDoc, {
      isPlaying: false,
    });
    return false;
  }

  suscribeToCurrentPlaying() {
    this.unsuscribeToCurrentPlaying();
    const smartListCollection = collection(this.session.sessionDoc, 'smartList');
    this.currentPlayingDoc = doc(smartListCollection, 'currentPlaying') as DocumentReference<IMedia>;
    this.currentPlaying$ = documentFromOnSnapshot(this.currentPlayingDoc)
      .pipe(valueChanges<IMedia>())
      .pipe(
        map(song => song as IMedia),
        distinctUntilChanged(
          (previous, current) =>
            previous?.uid === current?.uid &&
            previous?.current_tone === current?.current_tone &&
            previous?.mode === current?.mode
        ),
        filter(song => song !== undefined && song !== null),
        map(this.smartList.mapToFlowPlayer(this.devideKs.type)),
        tap(this.smartList.currentSong$)
      );
    this.subCurrentPlayingRemote = this.currentPlaying$.subscribe({
      next: this.onChangeCurrentPlaying.bind(this),
    });
  }

  suscribeToRemoteControls() {
    this.unsuscribeToRemoteControls();
    const smartListCollection = collection(this.session.sessionDoc, 'smartList');
    this.controlsDoc = doc(smartListCollection, 'controls') as DocumentReference<RemoteControlsInterface>;
    this.controlsObject = this.db.object(`Session/${this.session.currentSessionToken}/controls`);
    this.controls$ = documentFromOnSnapshot(this.controlsDoc).pipe(valueChanges<RemoteControlsInterface>());
    this.subControlsPlayerRemote = this.controls$.subscribe(controls => {
      this.controls = controls;

      if (controls) {
        if (controls.pause) {
          // let elvideo = this.videoElement;
          this.player.pause();
          updateDoc(this.controlsDoc, {
            pause: false,
            isPlaying: false,
          });
        }
        if (controls.play) {
          // let elvideo = this.videoElement;
          this.player.resume();

          updateDoc(this.controlsDoc, {
            play: false,
            isPlaying: true,
          });
        }
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  iCanSingThisSong(song) {
    if (this.user.premium) {
      return true;
    }
    return true;
  }

  async updateCurrentSong(song: IMedia): Promise<IMedia> {
    if (!this.iCanSingThisSong(song)) {
      return song;
    }
    return setDoc(this.currentPlayingDoc, song)
      .then(() => song)
      .catch(e => {
        console.error(e);
        return song;
      });
  }

  async playSong(song: IMedia, user: UserSessionResponseInterface): Promise<IMedia> {
    console.log('playSong', song);
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const result = await this.smartList.addSongToSmartList(song, user, true).pipe(take(2)).toPromise();
      console.log('result', result);

      await this.updateCurrentSong(song);
      console.log('current song updated');
      this.player.setIndexSongCurrent(this.player.list.length - 1);
      // await this.updateCurrentSong(song);
    } catch (e) {
      console.error(e);
    }
    return song;
  }

  unsuscribeToCurrentPlaying() {
    console.log('unsuscribeToCurrentPlaying');
    this.subCurrentPlayingRemote?.unsubscribe();
  }
  unsuscribeToRemoteControls() {
    this.subControlsPlayerRemote?.unsubscribe();
  }
  hasChangeTone(song: IMedia) {
    return (
      this.currentPlaying &&
      this.currentPlaying.uid === song.uid &&
      this.currentPlaying?.current_tone !== song?.current_tone &&
      this.currentPlaying?.current_tone !== undefined &&
      this.currentPlaying?.current_tone !== null &&
      song?.current_tone !== undefined &&
      song?.current_tone !== 0
    );
  }
  onChangeCurrentPlaying(song: IMedia) {
    // this.player.sendVideoAnalytics();
    console.log('onChangeCurrentPlaying', song);
    if (song && (song.video || song.hls)) {
      if (this.hasChangeTone(song)) {
        const tone = song?.current_tone > 0 ? `+${song?.current_tone.toString()}` : song?.current_tone.toString();
        this.onChangeTone(song, tone);
        this.remoteSongCheck.next(song);
        return;
      }
      if (song?.current_tone !== 0 && song?.current_tone !== undefined) {
        this.checkTone(song);
      }
      this.playOnFlowPlayer(song);
    }
    this.remoteSongCheck.next(song);
  }

  checkTone(song: IMedia) {
    if (!this.user.authenticated) {
      // this.notifications.notify('onlyPremium');
      song.current_tone = 0;
      if (this.currentPlaying) {
        this.currentPlaying.current_tone = 0;
        updateDoc(this.currentPlayingDoc, {
          current_tone: 0,
        });
      }
      return false;
    }
    return true;
  }

  onChangeTone(song: IMedia, tone: string) {
    if (!this.checkTone(song)) {
      tone = '0';
    }
    song.sources = getSourceAndTone(song, tone, undefined, undefined, this.devideKs.type);
    this.playOnFlowPlayer(song);
  }

  iAmOnPlayerView() {
    return !(this.location.path().indexOf('player') === -1 || this.location.path().indexOf('player-ads') !== -1);
  }

  playOnFlowPlayer(song: IMedia) {
    this.currentPlaying = song;
    // If i am not in the player or player-ads page
    if (!this.iAmOnPlayerView()) {
      // this.router.navigate(['player', this.currentPlaying.tag]);
      const paramsPlayer = song.mode === 'game' ? { mode: 'game', uid: song.uid } : { uid: song.uid };

      const goToPlayer = () => {
        if (this.userLastAction.getUserLastAction()) {
          paramsPlayer['action'] = this.userLastAction.getUserLastAction();
        } else if (song.actionPrev) {
          paramsPlayer['action'] = song.actionPrev;
        }
        paramsPlayer.mode = song.mode;
        if (this.user.authenticated) {
          this.router.navigate(['player'], { queryParams: paramsPlayer });
        }
      };

      if ((this.devideKs.devicePlatform.name = 'movistar')) {
        if (this.songCounter.canISingASong()) {
          goToPlayer();
        } else {
          if (!this.user.authenticated) {
            this.router.navigate(['connect-movistar'], { queryParams: paramsPlayer });
          } else {
            this.router.navigate(['connect-pay-movistar'], { queryParams: paramsPlayer });
          }
        }
      } else {
        if (!this.user.premium && this.adsService.mustBeShowAd() && song.mode !== 'game') {
          this.router.navigate(['player-ads'], { queryParams: paramsPlayer });
        } else {
          goToPlayer();
        }
      }
    } else {
      const paramsPlayer = song.mode === 'game' ? { mode: 'game', uid: song.uid } : { uid: song.uid };

      if ((this.devideKs.devicePlatform.name = 'movistar')) {
        if (this.songCounter.canISingASong()) {
          this.player.playSong(song);
        } else {
          if (!this.user.authenticated) {
            this.router.navigate(['connect-movistar'], { queryParams: paramsPlayer });
          } else {
            this.router.navigate(['connect-pay-movistar'], { queryParams: paramsPlayer });
          }
        }
      } else {
        if (!this.user.premium && this.adsService.mustBeShowAd() && song.mode !== 'game') {
          this.router.navigate(['player-ads'], { queryParams: paramsPlayer });
        } else {
          this.player.playSong(this.currentPlaying);
        }
      }
    }
  }

  previus(song: IMedia): boolean {
    this.updateCurrentSong(song);
    return true;
  }

  next(song: IMedia): boolean {
    this.updateCurrentSong(song);
    return true;
  }

  deleteCurrentSong() {
    this.noPlaying();
    return deleteDoc(this.currentPlayingDoc).then(() => null);
  }
}
